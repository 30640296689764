import firebase, { REQUEST_TYPES } from 'fb';
import { RawDraftContentState } from 'draft-js';
import { Event, EventTime, Location } from 'apps/Events/types';
import { possibleFirstUrlPaths } from 'components/Player/utils/multiplePlayerApps';
import { Theme } from '@material-ui/core';

export type TypeOfField<ObjectType, FieldType extends keyof ObjectType> = ObjectType[FieldType];

export enum SubscriptionType {
  unset = 0,
  free = 1,
  student_free = 2,
  teacher_free = 3,
  premium = 4,
  pro = 5,
  student_pro = 6,
  teacher_pro = 7,
  hide = 8,
}

export type SchoolMember = {
  role: string;
  uid: string;
  validUntil: firebase.firestore.Timestamp;
  studentBypass?: boolean;
};

export type Membership = {
  currentPeriodEnd: firebase.firestore.Timestamp;
  dateCreated: firebase.firestore.Timestamp;
  cancelAt?: firebase.firestore.Timestamp;
  family: boolean;
  generatedId: string;
  lastUpdated: firebase.firestore.Timestamp;
  membershipPeriodInWeeks: number;
  membershipType: string;
  paymentUser: string;
  status: string;
  stripeSubscriptionId: string;
  userNames: Array<string>;
  users: Array<string>;
  // TODO: Rest of the fields
};

export type User = {
  subscription?: {
    subscriptionType?: string;
    currentSubscriptionRef?: firebase.firestore.DocumentReference;
    schoolBypass?: boolean;
    // Dont know if we need this here
    //currentSubscriptionRef?: firebase.firestore.DocumentReference;
    //pastSubscriptionRefs?: Array<firebase.firestore.DocumentReference>;
  };
  aliasChild?: boolean;
  birthday?: firebase.firestore.Timestamp;
  country: string;
  email: string;
  name: string;
  phoneNumber: string;
  picture: string;
  uid: string;
  children?: Array<string>;
  languageCode?: 'nb-NO' | 'en-US';
  newNotifications?: number;
  events?: {
    defaultRole?: string;
    admin?: boolean;
    certification?: string;
    class?: string;
    connectedLocations?: Array<string>;
    connectedTeachers?: Array<string>;
    description?: string;
    instrumentLevels?: Record<string, number>;
    school?: string;
    membership?: {
      currentMembershipRef?: firebase.firestore.DocumentReference;
      membershipType?: string;
      family?: boolean;
      cancelAt?: firebase.firestore.Timestamp;
      pastMembershipRefs?: Array<firebase.firestore.DocumentReference>;
      hasPaymentMethod?: boolean;
    };
  };
  player?: {
    admin?: boolean;
  };
  resources?: {
    admin?: boolean;
  };
  notificationTokens?: Array<string>;
  parents?: Array<string>;
  mailSubscriptions?: {
    newSongs?: boolean;
    eventStart?: boolean;
  };
  feide?: {
    dataExpirationDate?: firebase.firestore.Timestamp;
    lastLoggedInDate?: firebase.firestore.Timestamp;
    nextCheckDate?: firebase.firestore.Timestamp;
    deleted?: boolean;
  };
  licenseAdmin?: boolean;
};
export const UserFields = [
  'aliasChild',
  'birthday',
  'country',
  'email',
  'name',
  'phoneNumber',
  'picture',
  'uid',
  'children',
  'subscriptionType',
  'notificationTokens',
  'parents',
  'downgradeDate',
  'currentSubscriptionDowngrade',
] as const;

export type UserField = typeof UserFields[number];
export type FilteredUser = {
  aliasChild?: boolean;
  birthday?: firebase.firestore.Timestamp;
  country?: string;
  email?: string;
  name?: string;
  phoneNumber?: string;
  picture?: string;
  uid?: string;
  subscriptionType?: SubscriptionType;
  children?: Array<string>;
  parents?: Array<string>;
  notificationTokens?: Array<string>;
  currentSubscriptionDowngrade?: { downgradeDate: Date; newSubscriptionType: string };
};

export type PlayerType = typeof possibleFirstUrlPaths[number];

export type SongPart = {
  bars: Array<{ stressedBeats: number[]; chords: Array<{ chord: number; minor: boolean; length: number }>; length: number }>;
  length: number;
};

export type SongArrangement = {
  part: number;
  repetitions: number;
  bpmChange?: Array<{ rep: number; value: number }>;
};

// TODO(ks): When changing to get genres from DB:
//           Remove this enum or create it from DB entries
export enum SongGenre {
  BLUES = 'Blues',
  CLASSIC = 'Classic',
  COUNTRY = 'Country',
  DISCO = 'Disco',
  EDM = 'EDM',
  FOLK = 'Folk',
  HIPHOP = 'Hip Hop',
  JAZZ = 'Jazz',
  JOIK = 'Joik',
  POP = 'Pop',
  RB = 'R&B',
  REGGAE = 'Reggae',
  ROCK = 'Rock',
}

// TODO(ks): When changing to get themes from DB:
//           Remove this enum or create it from DB entries
export enum SongTheme {
  CHRISTIAN = 'Christian',
  CHRISTMAS = 'Christmas',
  DISNEY = 'Disney',
  ENVIRONMENT = 'Environment',
  EUROVISION = 'Eurovision',
  FILM_STAGE = 'Film/Stage',
  FRIENDSHIP = 'Friendship',
  HALLOWEEN = 'Halloween',
  LOVE = 'Love',
  MAY17 = '17. May',
  MULTICULTURAL = 'Multicultural',
  MYBAND = 'MyBand',
  PEACE = 'Peace',
  SAMI = 'Sami',
}

// TODO(ks): When changing to get VideoCategory from DB:
//           Remove this enum or create it from DB entries
export enum VideoCategory {
  LYRICS = 'LYRICS',
  MUSICVIDEO = 'MUSICVIDEO',
  KARAOKE = 'KARAOKE',
  DANCE = 'DANCE',
  CHALLENGE = 'CHALLENGE',
  OTHER = 'OTHER',
}

export type Song = {
  private?: boolean;
  mediaSource: string;
  id: string;
  favorite?: boolean;
  arrangement: SongArrangement[];
  artist: string;
  beats: number[];
  credits: Array<unknown>;
  creator: string;
  dateCreated: firebase.firestore.Timestamp;
  genre: string[];
  theme: string[];
  videoCategory: VideoCategory;
  key: number;
  language: string[];
  lastUpdated: firebase.firestore.Timestamp;
  level: number;
  parts: Array<SongPart>;
  relatedLinks: {
    icon: string;
    name: string;
    url: string;
  }[];
  startTime: number;
  title: string;
  youtubeEndTime: number;
  youtubeLink: string;
  youtubeStartTime: number;
  subscription: {
    [key: string]: {
      subscription: string;
      manually: boolean;
    };
  };
  chordsContained: { chord: number; minor: boolean }[];
  hasSplitBar?: boolean;
  fileUrls?: string[];
  explicit?: boolean;
  hidden?: {
    [key: string]: boolean;
  };
};
export type PrivateSong = {
  mediaSource?: string;
  id?: string;
  favorite?: boolean;
  arrangement: SongArrangement[];
  artist: string;
  beats: number[];
  credits: Array<unknown>;
  creator?: string;
  dateCreated?: firebase.firestore.Timestamp;
  genre: string[];
  theme: string[];
  videoCategory?: VideoCategory;
  key: number;
  language?: string[];
  lastUpdated?: firebase.firestore.Timestamp;
  level: number;
  parts: Array<SongPart>;
  relatedLinks: {
    icon: string;
    name: string;
    url: string;
  }[];
  startTime: number;
  title: string;
  youtubeEndTime?: number;
  youtubeLink?: string;
  youtubeStartTime?: number;
  chordsContained: { chord: number; minor: boolean }[];
  hasSplitBar?: boolean;
  fileUrls?: string[];
  explicit?: boolean;
  hidden?: {
    [key: string]: boolean;
  };
  private: boolean;
};

export type FavoriteSongsDoc = {
  userId: string;
  lastUpdated: firebase.firestore.Timestamp;
  favoriteSongs: {
    [player: string]: string[];
  };
};

export type ChordVideo = {
  level: number;
  url: string;
  mostEfficient: boolean;
  disabled?: boolean;
};

export type ChordJSON = {
  numChords: number;
  chord: number;
  octave: number;
  minor: boolean;
};

export type Chord = {
  [key: string]: Array<ChordVideo>;
  minor: Array<ChordVideo>;
  major: Array<ChordVideo>;
};
export type ChordVideos = {
  [key: string]: Array<Chord>;
};
export type SongsMap = {
  [key: string]: Song;
};

export type Playlist = {
  id?: string;
  editInviteCode: string;
  editPermissionUsers: Array<string>;
  lastEditedDate: firebase.firestore.Timestamp;
  name: string;
  owner: string;
  public: boolean;
  readInviteCode: string;
  readPermissionUsers: Array<string>;
  sharedToClasses?: Array<string>;
  songs: firebase.firestore.DocumentReference<Song>[];
  player: PlayerType;
};

export type OrganizationMemberType = 'owner' | 'admin' | 'employee';

export type Organization = {
  contact: {
    email: string;
    phoneNumber: string;
  };
  description: RawDraftContentState;
  employees: Array<string>;
  admins: Array<string>;
  owners: Array<string>;
  name: string;
  picture: string;
};

export type Notification = {
  body: string;
  click_action: string;
  read: boolean;
  time: firebase.firestore.Timestamp;
  template?: string;
  title: string;
};
export type ChatType = 'single' | 'group';

export interface Chat {
  type: ChatType;
}

export interface ChatSingle extends Chat {
  type: 'single';
  lastMessageAt: firebase.firestore.Timestamp;
  users: Array<string>;
  usersMap: Record<string, true>;
}
export interface ChatGroup extends Chat {
  type: 'group';
  event: firebase.firestore.DocumentReference<Event>;
}

export type ChatMessage = {
  senderId: string;
  sendTime: firebase.firestore.Timestamp;
  text: string;
};

export interface Request<ReceiverType = User> {
  type: REQUEST_TYPES;
  expiry: firebase.firestore.Timestamp;
  sender: firebase.firestore.DocumentReference<User>;
  receiver: firebase.firestore.DocumentReference<ReceiverType>;
  message?: string;
  closed?: boolean;
}

export interface OrganizationMemberRequest extends Request {
  type: REQUEST_TYPES.ORGANIZATION_MEMBER;
  memberType: OrganizationMemberType;
  organization: firebase.firestore.DocumentReference<Organization>;
}

export interface LocationConnectedHostRequest extends Request {
  type: REQUEST_TYPES.LOCATION_CONNECTED_HOST;
  location: firebase.firestore.DocumentReference<Location>;
}

export interface EventRequest<ReceiverType = User> extends Request<ReceiverType> {
  event: firebase.firestore.DocumentReference<Event>;
  eventTimes: Array<firebase.firestore.DocumentReference<EventTime>>;
  price: number;
}

export interface EventToLocationRequest extends EventRequest<User | Organization> {
  type: REQUEST_TYPES.EVENT_TO_LOCATION;
  location: firebase.firestore.DocumentReference<Location>;
}

export interface LocationToEventRequest extends EventRequest<User | Organization> {
  type: REQUEST_TYPES.LOCATION_TO_EVENT;
  location: firebase.firestore.DocumentReference<Location>;
}

export interface EventToTeacherRequest extends EventRequest {
  type: REQUEST_TYPES.EVENT_TO_TEACHER;
}

export interface TeacherToEventRequest extends EventRequest<User | Organization> {
  type: REQUEST_TYPES.TEACHER_TO_EVENT;
  answerToRequest: firebase.firestore.DocumentReference<EventRequest> | null;
  paymentTo: firebase.firestore.DocumentReference<User | Organization>;
}

export interface ParentChildRequest extends Request {
  type: REQUEST_TYPES.PARENT_CHILD;
  child: firebase.firestore.DocumentReference<User>;
}

export type TeachingResourceDocument = {
  id?: string;
  hasParent: boolean;
  resourceType?: 'resource' | 'directory';
  isFavorite?: boolean;
  name: string;
  type: ResourceType;
  link?: string | File;
  images?: (string | File)[];
  subscription: string;
  onlySignedIn?: boolean;
};

export enum ResourceType {
  UNKNOWN = '',
  PDF = 'pdf',
  LINK = 'link',
  YOUTUBE = 'youtube',
  PLAYER = 'player',
  PLAYERRES = 'playerres',
  PLAYLIST = 'playlist',
  SLIDESHOW = 'slideshow',
}

export type ResourceDirectory = {
  id: string;
  hasParent: boolean;
  resourceType?: 'resource' | 'directory';
  name: string;
  isFavorite?: boolean;
  contents: Array<{ data: TeachingResourceDocument | ResourceDirectoryDocument; type: 'resource' | 'directory' }>;
  subscription: string;
};

export type ResourceDirectoryDocument = {
  id?: string;
  hasParent: boolean;
  resourceType?: 'resource' | 'directory';
  isFavorite?: boolean;
  name: string;
  contents: firebase.firestore.DocumentReference<TeachingResourceDocument | ResourceDirectoryDocument>[];
  subscription: string;
  onlySignedIn?: boolean;
};

export type FavoriteResources = {
  uid: string;
  favorites: Array<firebase.firestore.DocumentReference<TeachingResourceDocument | ResourceDirectoryDocument>>;
};

export type EventInvoice = {
  id?: string;
  eventId: string;
  eventName: string;
  eventTimes: string[];
  eventTargetGroup: string;
  ecPay: boolean;
  participantId: string;
  participantName: string;
  payerId: string;
  payerEmail: string;
  payerName: string;
  payerPhoneNumber: string;
  price: number;
  status: string;
  timeCreated: firebase.firestore.Timestamp;
  timeProcessed: firebase.firestore.Timestamp;
};

export type SchoolOnboarding = {
  isNew: boolean;
  submitterName: string;
  submitterRole: string;
  submitterEmail: string;
  submitterPhoneNumber: string;
  school: {
    name: string;
    orgNumber: string;
    municipality: {
      name: string;
      number: string;
    };
    feideId: string;
    studentAmount: string;
    teacherAmount: string;
  };
  time: firebase.firestore.Timestamp;
  principal: {
    name: string;
    phoneNumber: string;
    email: string;
  };
  optionalContact: {
    role: string;
    name: string;
    phoneNumber: string;
    email: string;
  };

  mailSent: boolean;
};

export type School = {
  feideId: string;
  feideOwnerId: string;
  feideOwnerName: string;
  name: string;
};

export type SchoolClass = {
  feideId: string;
  name: string;
};

export type SchoolInvoiceInfo = {
  invoiceSent?: boolean;
  invoiceSentDate?: firebase.firestore.Timestamp;
  invoicePaid: boolean;
  invoicePaidDate?: firebase.firestore.Timestamp;
  periodEnd?: firebase.firestore.Timestamp;
  isTrial?: boolean;
};

// TODO NEED TO CHANGE, todo made 25.05.2023
export type SchoolLicense = {
  subscriptionActivated: boolean;
  time: firebase.firestore.Timestamp;
  isNew: boolean;
  school: SchoolInfo;
  contact: {
    name: string;
    email: string;
    phoneNumber: string;
  };
  interestId: string;
  invoice: {
    currentInvoice: SchoolInvoiceInfo;
    pastInvoices: SchoolInvoiceInfo[];
    upcomingInvoice: SchoolInvoiceInfo;
  };
};

export type SchoolInfo = {
  name: string;
  municipality: {
    number: string;
    name: string;
  };
  feideId?: string;
  orgNumber?: string;
  studentAmount?: string;
  teacherAmount?: string;
};

export type CustomTheme = Theme & {
  testcolor: string;
  breakpoints: {
    values: {
      xs: number;
      sm: number;
      md: number;
      lg: number;
      xl: number;
    };
  };
  shape: {
    borderRadius: number;
  };
  typography: {
    fontFamily: string;
    useNextVariants: boolean;
    h1: {
      color: string;
      textAlign: string;
      fontWeight: number;
      fontSize: string;
      fontFamily: string;
      marginTop: string;
      marginBottom: string;
      whiteSpace: string;
      paddingBottom: number;
    };
    h2: {
      color: string;
      textAlign: string;
      fontSize: string;
      fontWeight: string;
      marginTop: number;
      marginBottom: number;
    };
    h3: {
      color: string;
      textAlign: string;
      fontSize: string;
    };
    h4: {
      color: string;
      textAlign: string;
      marginBottom: number;
      fontSize: string;
    };
    h5: {
      color: string;
    };
    h6: {
      color: string;
    };
    p: {
      color: string;
    };
    body2: {
      color: string;
    };
  };
  palette: {
    primary: {
      main: string;
      contrastText: string;
    };
    secondary: {
      main: string;
      contrastText: string;
    };
    error: {
      main: string;
      contrastText: string;
    };
    type: string;
    background: {
      default: string;
    };
    black: {
      main: string;
      light: string;
    };
    logo: {
      main: string;
      light: string;
    };
  };
  colors: {
    background: {
      primary: string;
      primaryLight: string;
      secondary: string;
      nav: string;
    };
    text: {
      main: string;
      disabled: string;
      light: string;
    };
    button: {
      primary: string;
      primaryText: string;
      secondary: string;
      secondaryText: string;
    };
    elements: {
      primary: string;
      primaryLight: string;
      primaryDark: string;
      secondary: string;
      contrast: string;
    };
    red: string;
    yellow: string;
    green: string;
  };
  hover: string;
  shadow: string;
  transparent: string;
  logo: string;
  logoDark: string;
  invert: string;
};
