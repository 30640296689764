import { stringToSubscriptionType } from 'components/Player/hooks/useSubscription';

export function filterObjectByTerm(obj, properties, term) {
  // Helper function to reduce some code, and make it more readable
  // Takes in an object `obj`, and searches over the `properties` with
  // a trimmed search term on lower cased results from the object/property
  // combos
  if (!Array.isArray(properties)) {
    return null;
  }
  return properties.reduce((curr, item) => {
    return curr || termify(obj[item]).includes(termify(term));
  }, false);
}

export function filterArrayByTerm(arr, term) {
  // Checks if every [lower cased] item of array `arr` includes the trimmed search term.
  return arr.reduce((curr, item) => curr || termify(item).includes(termify(term)), false);
}

export function titleize(input) {
  // Takes in an input, and does minor job trying to titleize it.
  // Capitalize every word, except the "exceptions".

  if (!input) {
    return;
  }
  const exception = ['a', 'an', 'the', 'of', 'in', 'on'];
  return input
    .split(' ')
    .map((word) => (exception.indexOf(word) > -1 ? word : capitalize(word)))
    .join(' ');
}

export function termify(str) {
  // Helper function to lower case and trim text,
  // very useful when comparing text.
  if (!str) {
    return '';
  }
  return str.trim().toLowerCase();
}

export function chordsContainedOfSongParts(parts) {
  const chords = [];
  for (const part of parts) {
    for (const bar of part.bars) {
      for (const chord of bar.chords) {
        if (!chords.some((c) => chord.chord === c.chord && chord.minor === c.minor)) {
          if (chord.chord !== 'pause' && !chord.pause) {
            chords.push({ chord: chord.chord, minor: chord.minor });
          }
        }
      }
    }
  }
  return chords;
}

function songLevelIsSameChord(nextChord, prevChord) {
  // If the nextChord is pause there is no chord change
  if (nextChord.pause || nextChord.chord === 'pause') {
    return true;
  }
  // Is the same chord
  if (nextChord.chord === prevChord.chord && nextChord.minor === prevChord.minor) {
    return true;
  }
  // One is minor other is major
  if (nextChord.minor !== prevChord.minor) {
    // Check of the minor one is below the major one in the circle of fifths
    const minorChord = nextChord.minor ? nextChord : prevChord;
    const majorChord = nextChord.minor ? prevChord : nextChord;
    if ((minorChord.chord + 3) % 12 === majorChord.chord) {
      return true;
    }
  }
  return false;
}

function timeOfBeat(index, beats) {
  const wholeBeat = Math.floor(index);
  if (wholeBeat === index) {
    return beats[wholeBeat];
  }
  const fractionalBeat = index - wholeBeat;
  if (wholeBeat < beats.length - 1) {
    return beats[wholeBeat + 1] * fractionalBeat + beats[wholeBeat] * (1 - fractionalBeat);
  }
  return beats[wholeBeat] * (1 + fractionalBeat) - fractionalBeat * beats[wholeBeat - 1];
}

export function songLevelFromChordChanges(arrangement, parts, beats) {
  const bottomBPM = 90;
  const topBPM = 2 * bottomBPM;
  const percentage = 0.9;
  const chordChanges = [];
  let lastChord = undefined;
  let beatIndex = 0;
  for (const arr of arrangement) {
    for (let i = 0; i < arr.repetitions; i++) {
      for (const bar of parts[arr.part].bars) {
        for (const chord of bar.chords) {
          if (lastChord === undefined || !songLevelIsSameChord(chord, lastChord)) {
            chordChanges.push(beatIndex);
            lastChord = chord;
          }
          beatIndex += chord.length;
        }
      }
    }
  }
  // Only one chord
  if (chordChanges.length <= 1) {
    return 1;
  }

  const lengthOfChanges = [];
  let minTime = undefined;
  let timeSum = 0;
  for (let i = 1; i < chordChanges.length; i++) {
    const time = timeOfBeat(chordChanges[i], beats) - timeOfBeat(chordChanges[i - 1], beats);
    lengthOfChanges.push(time);
    if (minTime === undefined || time < minTime) {
      minTime = time;
    }
    timeSum += time;
  }
  for (let level = 2; level <= 5; level++) {
    // Check if it is inside each level
    const timeThreshold = (60 / topBPM) * Math.pow(2, 6 - level);
    if (minTime > timeThreshold) {
      return level;
    }

    let percentageInsideThreshold = 0;
    for (const time of lengthOfChanges) {
      if (time > timeThreshold) {
        percentageInsideThreshold += time / timeSum;
      }
    }
    if (percentageInsideThreshold >= percentage) {
      return level;
    }
  }

  return 6;
}

export const get6ChordSystem = (chord, minor) => {
  // Returns the toggle states index of the 6 chord neighborhood of a chord in the order:
  // Left major, middle major, right major, left minor, middle minor, right minor
  const neighbors = [];
  const middleChord = minor ? (chord + 3) % 12 : chord;
  for (const i of [0, 1]) {
    for (const j of [5, 0, 7]) {
      neighbors.push([i, (middleChord + j + i * 9) % 12]);
    }
  }
  return neighbors;
};

export const get12ChordSystem = (chord, minor, neighborhood6) => {
  // Returns the toggle states index of the 6 chord neighborhood of a chord in the order:
  // Left major, middle major, right major, left minor, middle minor, right minor
  const neighbors = neighborhood6 ? [...neighborhood6] : get6ChordSystem(chord, minor);
  const middleChord = minor ? (chord + 3) % 12 : chord;
  for (const i of [0, 1]) {
    for (const j of [5, 0, 7]) {
      neighbors.push([i, (middleChord + j + (1 - i) * 9) % 12]);
    }
  }
  return neighbors;
};

export function subscriptionFromLevelAndChords(level, chordsContained) {
  if (level === 1) {
    return 'free';
  }
  const chordsInPremium = get6ChordSystem(0, false);
  if (
    chordsContained.every((chordInSong) =>
      chordsInPremium.some((chordInPremium) => chordInSong.chord === chordInPremium[1] && chordInSong.minor === (chordInPremium[0] === 1)),
    )
  ) {
    return 'premium';
  }

  return 'pro';
}

export function filterSongBy(filterObj, arrayContainsFilterObj, recipientObj, favoriteSort, chordSort, noSplitBar, allowExplicitSongs = true) {
  const propList = Object.keys(filterObj);
  let currentList = recipientObj;

  /*const allEmpty = Object.values(filterObj).every((value) => Object.keys(value).length === 0);
  if (allEmpty) {
    return currentList;
  }*/
  for (let i = 0; i < propList.length; i++) {
    const prop = propList[i];
    if (Object.keys(filterObj[prop]).length === 0) {
      continue;
    }
    currentList = currentList.filter((song) => filterObj[prop][song[prop]] === true);
  }
  const arrayContainsFilterKeys = Object.keys(arrayContainsFilterObj);
  for (let i = 0; i < arrayContainsFilterKeys.length; i++) {
    const arrayProp = arrayContainsFilterKeys[i];
    if (Object.keys(arrayContainsFilterObj[arrayProp]).length === 0) {
      continue;
    }

    currentList = currentList.filter((song) => song[arrayProp].some((propArrayValue) => arrayContainsFilterObj[arrayProp][propArrayValue] === true));
  }
  if (favoriteSort) {
    currentList = currentList.filter((song) => song.favorite === true);
  }
  if (!allowExplicitSongs) {
    currentList = currentList.filter((song) => !song.explicit);
  }

  if (chordSort) {
    if (chordSort.mustContain && chordSort.mustContain.length > 0) {
      currentList = currentList.filter((song) =>
        chordSort.mustContain.every((mustContainChord) =>
          song.chordsContained?.some((chordOfSong) => chordOfSong.chord === mustContainChord.chord && chordOfSong.minor === mustContainChord.minor),
        ),
      );
    }

    if (chordSort.cantContain && chordSort.cantContain.length > 0) {
      currentList = currentList.filter((song) =>
        chordSort.cantContain.every(
          (cantContainChord) =>
            !song.chordsContained?.some((chordOfSong) => chordOfSong.chord === cantContainChord.chord && chordOfSong.minor === cantContainChord.minor),
        ),
      );
    }
  }

  if (noSplitBar) {
    currentList = currentList.filter((song) => !song.hasSplitBar);
  }

  return currentList;
}

export const hasSplitBar = (parts) => {
  for (let i = 0; i < parts.length; i++) {
    for (let j = 0; j < parts[i].bars.length; j++) {
      if (parts[i].bars[j].chords.length > 1) {
        return true;
      }
    }
  }
  return false;
};

export function capitalize(str) {
  if (!(typeof str === 'string')) {
    return;
  }
  if (str.length < 1) {
    return;
  }
  return str[0].toUpperCase() + str.slice(1);
}

export const convertToRoman = (num) => {
  const roman = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 };
  let str = '';
  for (const i of Object.keys(roman)) {
    const q = Math.floor(num / roman[i]);
    num -= q * roman[i];
    str += i.repeat(q);
  }
  return str;
};

export const getWhitePianoFinger = (chord, minor) => {
  if (minor) {
    switch (chord) {
      case 0:
        return '3';
      case 5:
        return '1+';
      case 7:
        return '5+';
      default:
        return undefined;
    }
  } else {
    switch (chord) {
      case 3:
        return '1';
      case 8:
        return '5';
      case 10:
        return '4';
      default:
        return undefined;
    }
  }
};
export const noteOnLowestGuitarString = (note) => {
  const notesAboveE = (note + 5) % 12;
  return { string: Math.floor(notesAboveE / 5), fret: notesAboveE % 5 };
};

export const bassTabsFromChords = (chords) => {
  const notes = [];
  let beatCount = 0;
  chords.forEach((chord) => {
    notes.push({
      ...noteOnLowestGuitarString(chord.chord),
      chord: chord.chord,
      minor: chord.minor,
      beat: beatCount,
    });
    beatCount += chord.length;
  });
  return notes;
};

export const userAccessToSong = (song, currentPlayer, userSubscription, isSchoolUser, isSharedWithClass) => {
  if (song?.private) {
    return { access: true, reason: undefined };
  }
  const songSubscription = stringToSubscriptionType(song.subscription[currentPlayer].subscription);
  if (songSubscription > userSubscription && !isSharedWithClass) {
    return { access: false, reason: 'subscription' };
  }

  if (song?.explicit && isSchoolUser) {
    return { access: false, reason: 'explicit' };
  }

  return { access: true, reason: undefined };
};
