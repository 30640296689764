import URL from 'URLS';
import { SubscriptionType } from 'types';

// all player apps must be declared here
export const possiblePlayerURLs = [
  URL.PLAYER,
  URL.CHOIRPLAYER,
  URL.BOOMWHACKERSPLAYER,
  URL.UKULELEPLAYER,
  URL.GUITARPLAYER,
  URL.WHITEPIANOPLAYER,
  URL.PIANONOTATIONPLAYER,
  URL.BLACKJAZZPIANOPLAYER,
  URL.BASSTABSPLAYER,
  URL.OPENTUNINGPLAYER,
  URL.SINGPLAYER,
];

export const possibleFirstUrlPaths = possiblePlayerURLs.map((url) => url.split('/')[1]);

export const getFirstUrlPath = () => {
  const firstPath = location.pathname.split('/')[1].toLowerCase();
  if (possibleFirstUrlPaths.includes(firstPath)) {
    return firstPath;
  }
  return possibleFirstUrlPaths[0];
};

export const getFirstUrlPathDash = () => getFirstUrlPath() + '-';
export const getFirstUrlPathUppercase = () => getFirstUrlPath().toUpperCase();
export const getHomePath = () => URL[getFirstUrlPathUppercase()];
export const getPlaylistPath = () => URL[getFirstUrlPathUppercase() + '_PLAYLISTS'];
export const getComposerPath = () => URL[getFirstUrlPathUppercase() + '_COMPOSER'];
export const getSettingsPath = () => URL[getFirstUrlPathUppercase() + '_SETTINGS'];
export const getInvitePath = () => URL[getFirstUrlPathUppercase() + '_INVITE'];
export const getSubscribePath = () => URL[getFirstUrlPathUppercase() + '_SUBSCRIBE'];

export const generatePlaylistInviteLink = (playlistId, code, player, showMessages = true) => {
  const origin = window.location.origin;
  return origin + URL[player.toUpperCase() + '_INVITE'] + playlistId + '/' + code + (showMessages ? '' : '/true');
};

export const getHighestLevelSort = () => {
  return null;
};

const chordSortCantContain6Chords = (chord) => {
  // Calculated cantContain chords when the only allowed chords are a 6 chord system in {chord}
  const cantContain = [];
  for (const i of [0, 1]) {
    for (const j of [1, 2, 3, 4, 6, 8, 9, 10, 11]) {
      cantContain.push({ minor: i === 1, chord: (chord + j + i * 9) % 12 });
    }
  }
  return cantContain;
};

const chordSortCantContain12Chords = (chord) => {
  // Calculated cantContain chords when the only allowed chords are a 12 chord system in {chord}
  const relativeCantContainChords = [1, 3, 6, 8, 10, 11];
  const cantContain = [];
  for (const j of relativeCantContainChords) {
    const absoluteChord = (chord + j) % 12;
    cantContain.push({ minor: false, chord: absoluteChord });
    cantContain.push({ minor: true, chord: absoluteChord });
  }

  return cantContain;
};

export const getChordSort = (firstUrlPath) => {
  if (firstUrlPath === 'ukuleleplayer' || firstUrlPath === 'boomwhackersplayer' || firstUrlPath === 'whitepianoplayer') {
    // 6 chord system in C
    return {
      cantContain: chordSortCantContain6Chords(3),
      mustContain: [],
    };
  }

  if (firstUrlPath === 'guitarplayer') {
    // 6 chord system in G
    return {
      cantContain: chordSortCantContain6Chords(10),
      mustContain: [],
    };
  }

  if (firstUrlPath === 'opentuningplayer' || firstUrlPath === 'singplayer') {
    // No filtering of chords
    return {
      cantContain: [],
      mustContain: [],
    };
  }

  // 6 chord system in A
  return {
    cantContain: chordSortCantContain6Chords(0),
    mustContain: [],
  };
};

// Parameters describing the properties of a song that are needed for a song to be playable in a specific player
const playableParameters = {
  player: { noSplitBar: false, chordsCantContain: [] },
  ukuleleplayer: { noSplitBar: false, chordsCantContain: [] },
  guitarplayer: { noSplitBar: false, chordsCantContain: [] },
  whitepianoplayer: { noSplitBar: true, chordsCantContain: chordSortCantContain6Chords(3) },
  choirplayer: { noSplitBar: false, chordsCantContain: [] },
  basstabsplayer: { noSplitBar: false, chordsCantContain: [] },
  boomwhackersplayer: { noSplitBar: true, chordsCantContain: chordSortCantContain6Chords(3) },
  opentuningplayer: { noSplitBar: false, chordsCantContain: [] },
  singplayer: { noSplitBar: false, chordsCantContain: [] },
};

export const hideChordWheelInSongList = (firstUrlPath = '') => {
  if (firstUrlPath === '') {
    firstUrlPath = getFirstUrlPath();
  }
  // Hide for sing player singe chords do not matter
  if (firstUrlPath === 'singplayer') {
    return true;
  }
  return playableParameters[firstUrlPath].chordsCantContain.length !== 0;
};

export const hideLevelSliderInSongList = (firstUrlPath = '') => {
  if (firstUrlPath === '') {
    firstUrlPath = getFirstUrlPath();
  }
  // Hide for sing player singe level does not matter (no chords)
  if (firstUrlPath === 'singplayer') {
    return true;
  }
  return false;
};

export const checkIfAppUsesSplitBar = (firstUrlPath = '') => {
  if (firstUrlPath === '') {
    firstUrlPath = getFirstUrlPath();
  }
  return !playableParameters[firstUrlPath].noSplitBar;
};

const anyCommonChordsInLists = (chordList1, chordList2) => {
  return chordList1.some((chord1) => chordList2.some((chord2) => chord1.chord === chord2.chord && chord1.minor === chord2.minor));
};

// Function with logic that calculates if a song is playable using the parameters given in playableParameters
const songIsPlayableFromParametersAndSong = (song, params) => {
  if (params.noSplitBar && song.hasSplitBar) {
    return false;
  }
  if (anyCommonChordsInLists(params.chordsCantContain, song.chordsContained)) {
    return false;
  }
  return true;
};

export const songIsPlayable = (song, player) => {
  if (player === undefined || player === null || player === '') {
    player = getFirstUrlPath();
  }
  const params = playableParameters[player];
  if (params === undefined) {
    return false;
  }

  return songIsPlayableFromParametersAndSong(song, params);
};

// Parameters for describing the properties of a song that are allowed in for subscription type for each player
const subscriptionParameters = {
  player: [
    { name: SubscriptionType[SubscriptionType.free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.student_free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.teacher_free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.premium], level: undefined, chordsCantContain: chordSortCantContain12Chords(0), level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.pro], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.student_pro], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.teacher_pro], level: undefined, chordsCantContain: [], level1ChordException: true },
  ],
  ukuleleplayer: [
    { name: SubscriptionType[SubscriptionType.free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.student_free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.teacher_free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.premium], level: 3, chordsCantContain: chordSortCantContain12Chords(3), level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.pro], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.student_pro], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.teacher_pro], level: undefined, chordsCantContain: [], level1ChordException: true },
  ],
  guitarplayer: [
    { name: SubscriptionType[SubscriptionType.free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.student_free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.teacher_free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.premium], level: 3, chordsCantContain: chordSortCantContain12Chords(10), level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.pro], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.student_pro], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.teacher_pro], level: undefined, chordsCantContain: [], level1ChordException: true },
  ],
  whitepianoplayer: [
    { name: SubscriptionType[SubscriptionType.free], level: 1, chordsCantContain: chordSortCantContain6Chords(3), level1ChordException: false },
    { name: SubscriptionType[SubscriptionType.student_free], level: 1, chordsCantContain: chordSortCantContain6Chords(3), level1ChordException: false },
    { name: SubscriptionType[SubscriptionType.teacher_free], level: 1, chordsCantContain: chordSortCantContain6Chords(3), level1ChordException: false },
    { name: SubscriptionType[SubscriptionType.premium], level: 3, chordsCantContain: chordSortCantContain6Chords(3), level1ChordException: false },
    { name: SubscriptionType[SubscriptionType.pro], level: undefined, chordsCantContain: chordSortCantContain6Chords(3), level1ChordException: false },
    { name: SubscriptionType[SubscriptionType.student_pro], level: undefined, chordsCantContain: chordSortCantContain6Chords(3), level1ChordException: false },
    { name: SubscriptionType[SubscriptionType.teacher_pro], level: undefined, chordsCantContain: chordSortCantContain6Chords(3), level1ChordException: false },
  ],
  choirplayer: [
    { name: SubscriptionType[SubscriptionType.free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.student_free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.teacher_free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.premium], level: 3, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.pro], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.student_pro], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.teacher_pro], level: undefined, chordsCantContain: [], level1ChordException: true },
  ],
  basstabsplayer: [
    { name: SubscriptionType[SubscriptionType.free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.student_free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.teacher_free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.premium], level: 3, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.pro], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.student_pro], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.teacher_pro], level: undefined, chordsCantContain: [], level1ChordException: true },
  ],
  boomwhackersplayer: [
    { name: SubscriptionType[SubscriptionType.free], level: 1, chordsCantContain: chordSortCantContain6Chords(3), level1ChordException: false },
    { name: SubscriptionType[SubscriptionType.student_free], level: 1, chordsCantContain: chordSortCantContain6Chords(3), level1ChordException: false },
    { name: SubscriptionType[SubscriptionType.teacher_free], level: 1, chordsCantContain: chordSortCantContain6Chords(3), level1ChordException: false },
    { name: SubscriptionType[SubscriptionType.premium], level: 1, chordsCantContain: chordSortCantContain6Chords(3), level1ChordException: false },
    { name: SubscriptionType[SubscriptionType.pro], level: undefined, chordsCantContain: chordSortCantContain6Chords(3), level1ChordException: false },
    { name: SubscriptionType[SubscriptionType.student_pro], level: undefined, chordsCantContain: chordSortCantContain6Chords(3), level1ChordException: false },
    { name: SubscriptionType[SubscriptionType.teacher_pro], level: undefined, chordsCantContain: chordSortCantContain6Chords(3), level1ChordException: false },
  ],
  opentuningplayer: [
    { name: SubscriptionType[SubscriptionType.free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.student_free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.teacher_free], level: 1, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.premium], level: 3, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.pro], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.student_pro], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.teacher_pro], level: undefined, chordsCantContain: [], level1ChordException: true },
  ],
  singplayer: [
    { name: SubscriptionType[SubscriptionType.free], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.student_free], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.teacher_free], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.premium], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.pro], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.student_pro], level: undefined, chordsCantContain: [], level1ChordException: true },
    { name: SubscriptionType[SubscriptionType.teacher_pro], level: undefined, chordsCantContain: [], level1ChordException: true },
  ],
};

// Function for checking if a song complies with a set of parameters from subscriptionParameters
const songCompliesWithSubscription = (song, params) => {
  const level = song.level;

  if (params.level !== undefined && level > params.level) {
    return false;
  }

  if (params.level1ChordException && level === 1) {
    return true;
  }

  if (params.chordsCantContain.length === 0) {
    return true;
  }

  if (anyCommonChordsInLists(params.chordsCantContain, song.chordsContained)) {
    return false;
  }

  return true;
};

// Function for deciding the lowest Subscription type that a song is allowed to be in given parameters
// for each subscription type given by subscriptionParameters
const subscriptionFromParametersAndSong = (song, params) => {
  let currentSubscriptionType = undefined;
  const numSubscriptions = params.length;

  for (let i = 0; i < numSubscriptions; i++) {
    const paramIndex = numSubscriptions - 1 - i;
    const songIsCompliant = songCompliesWithSubscription(song, params[paramIndex]);
    if (songIsCompliant) {
      currentSubscriptionType = params[paramIndex].name;
    } else {
      break;
    }
  }
  return currentSubscriptionType;
};

// Function for deciding the Subscription Type of a song in a given player. Returns hide if the song is not playable.
export const generateSubscriptionLevel = (song, player) => {
  if (player === undefined || player === null || player === '') {
    player = getFirstUrlPath();
  }
  const playParams = playableParameters[player];
  if (playParams === undefined) {
    return 'hide';
  }
  const playable = songIsPlayableFromParametersAndSong(song, playParams);
  if (!playable) {
    return 'hide';
  }
  const subParams = subscriptionParameters[player];
  if (subParams === undefined) {
    return 'hide';
  }
  const subscription = subscriptionFromParametersAndSong(song, subParams);
  if (subscription === undefined) {
    return 'hide';
  }
  return subscription;
};

export const generateSubscriptionLevelForAllPlayerApps = (subscriptionLevelInput, song) => {
  let subscriptionForAllPlayerApps = {};
  subscriptionLevelInput.map((app) => {
    if (app.subscription === 'auto') {
      subscriptionForAllPlayerApps[app.appName] = {
        subscription: generateSubscriptionLevel(song, app.appName),
        manually: false,
      };
    } else {
      subscriptionForAllPlayerApps[app.appName] = {
        subscription: app.subscription,
        manually: true,
      };
    }
  });
  return subscriptionForAllPlayerApps;
};

export const allAutoSubscription = () => {
  return possibleFirstUrlPaths.map((playerApp) => ({ appName: playerApp, subscription: 'auto' }));
};

export const generateHiddenForAllPlayerApps = (hiddenInput) => {
  let hiddenForAllPlayerApps = {};
  hiddenInput.map((app) => {
    hiddenForAllPlayerApps[app.appName] = app.hidden;
  });
  return hiddenForAllPlayerApps;
};

export const noHiddenPlayers = () => {
  return possibleFirstUrlPaths.map((playerApp) => ({ appName: playerApp, hidden: false }));
};

export const getNotationType = (urlPath) => {
  switch (urlPath) {
    case 'boomwhackersplayer':
      return 'boomwhackers';
    case 'choirplayer':
      return 'choir';
    case 'ukuleleplayer':
    case 'guitarplayer':
      return 'chordnotes';
    case 'basstabsplayer':
      return 'tabs';
    case 'whitepianoplayer':
      return 'whitepiano';
    case 'opentuningplayer':
      return 'openguitar';
    case 'singplayer':
      return 'onlyvideo';
    default:
      return 'chords';
  }
};

export const playerNeedsSchoolAccess = (urlPath) => {
  switch (urlPath) {
    case 'boomwhackersplayer':
      return true;
    default:
      return false;
  }
};

export const lightThemePlayer = () => {
  const player = location.pathname.split('/')[1].toLowerCase();
  if (!possibleFirstUrlPaths.includes(player)) {
    return false;
  }

  switch (player) {
    case 'player':
    case 'choirplayer':
    case 'ukuleleplayer':
    case 'guitarplayer':
      return true;
    default:
      return false;
  }
};
